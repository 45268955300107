import React from "react"
import { StaticQuery, graphql } from "gatsby"
import BackgroundImage from "gatsby-background-image"
import Title from "../components/title"
import BridgeTitle from "../components/bridgeTitle"

const Water = () => (
  <StaticQuery query={graphql`
    query {
      desktop: file(relativePath: { eq: "jeremy-bishop-1braZySlEKA-unsplash.jpg" }) {
        childImageSharp {
          fluid(quality: 75, maxWidth: 1920) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `}

  render={data => {
    // Set ImageData.
    const imageData = data.desktop.childImageSharp.fluid
    return (
      <BackgroundImage id="water" Tag="div" className="full-page" fluid={imageData}>
        
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-6">
              <div className="white-panel">
                <h1>Water &amp; Sewerage</h1>

                <p>Use <Title /> to remotely measure:</p>

                <ul>
                  <li>Water level (either with direct measurement, or using imagery and our AI Agents)</li>
                  <li>Quality (Clarity, Colour, pH etc...)</li>
                  <li>O2 Levels</li>
                  <li>Flow rate / Consumption</li>
                </ul>

                <p><Title /> can help you to:</p>

                <ul>
                  <li>Detect water leaks or excessive usage</li>
                  <li>Identify local drought potential</li>
                  <li>Control pumps or valves based on local inputs</li>
                  <li>Automatically adjust sampling of any metric (e.g. depending on local weather events)</li>
                </ul>

                <p><BridgeTitle /> allows you to:</p>

                <ul>
                  <li>Safely and securely present on-prem SCADA data from the cloud without costly per-user licensing</li>
                  <li>Report devices on secure networks to <Title /></li>
                  <li>Support unsolicited DNP3 devices on SCADA software that requires solicited</li>
                </ul>

                <p>Other solutions can be tailor made to meet your needs.</p>
                {/* <p>If so, then <Title /> has the potential to help. And if there's something you'd like to do that's a little unique, then definitely get in touch, because that's the fun stuff!</p> */}

              </div>
              
            </div>
          </div>
        </div>


      </BackgroundImage>
    )
  }}
/>
)

export default Water
